@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: visible !important;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blur-div {
  background-color: rgba(252, 252, 253, 0.2);
  backdrop-filter: blur(4px);
  position: relative;
  z-index: 0;
  width: 100%;
  padding: 20px 0;
}

.blur-div>.content {
  position: relative;
  z-index: 1;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.has-line:before,
.has-line:after {
  background-color: #CBD5E1;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  margin: 0 10px;
  width: 24%;
}

.base-container {
  padding-left: 120px;
  padding-right: 120px;
}

.orange-radial {
  background: radial-gradient(#EE7B30, #C66628);
}

.green-radial {
  background: radial-gradient(#81D5AE, #2DB978);
}

.dODGfY {
  background-color: #EEF2F6 !important;
}

.custom-bullet {
  width: 12px !important;
  height: 12px !important;
  background: #FFFFFF !important;
}

.custom-navigation.swiper-button-disabled {
  display: none !important;
}


.live-auction-lot:after {
  content: "";
  border-bottom: 26px solid #EDE0C4;
  border-right: 15px solid transparent;
  position: absolute;
  top: 0;
  right: -15px;
}

.text-between-lines {
  position: relative;
  text-align: center;
  line-height: 1.5; /* Adjust line height as needed */
}
.text-between-lines::before, .text-between-lines::after {
  content: "";
  position: absolute;
  top: 50%; /* Position vertically centered */
  width: 30%; /* Adjust the width of the lines */
  height: 1px; /* Thickness of the lines */
  background-color: #CBD5E1; /* Color of the lines */
}
.text-between-lines::before {
  left: 0;
  transform: translateY(-50%); /* Move the line up by half of its height */
}
.text-between-lines::after {
  right: 0;
  transform: translateY(-50%); /* Move the line up by half of its height */
}

@media (max-width: 768px) {
  .text-between-lines::before, .text-between-lines::after {
    width: 20%; /* Adjust width for smaller screens */
  }
}